import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import tw, { css, theme } from 'twin.macro'
import ActionNav from '../../components/action-nav'
import CardList from '../../components/card-list'
import FullCoverImage from '../../components/full-cover-image'
import HeaderBlock from '../../components/header-block'
import Heading from '../../components/heading'
import ImageHero from '../../components/image-hero'
import Layout from '../../components/layout'
import LinkBlock from '../../components/link-block'
import LogoGrid from '../../components/logo-grid'
import { overlays } from '../../components/overlays'
import Text from '../../components/text'
import VideoPlayer from '../../components/video-player'
import { global } from '../../styles/global'
import { fluid, remToPx } from '../../styles/typography'
import { lg, xl, xxl } from '../../utils/breakpoints'
import { flatten } from '../../utils/data'

const IndexPage = ({ data: { index }, location }) => {
  const page = useMemo(
    () =>
      flatten(index || {}, [
        'hero',
        'customerServiceHeader',
        'introduction',
        'introductionCta',
        'introductionVideo',
        'partnerBanner',
        'partnerCta',
        'whoWeAre',
      ]),
    [index]
  )

  return (
    <Layout pageTags={page.seoMetaTags} noIndex={page.noIndex} location={location}>
      <section id="hero" css={tw`relative`}>
        <ImageHero
          contentStyle={css`
            ${tw`col-span-4 md:col-span-7 xl:col-span-10`}
            h6 {
              ${tw`w-3/4`}
            }
          `}
          image={get(page, 'hero.image')}
          imageMobile={get(page, 'hero.imageMobile')}
          title={get(page, 'hero.titleNode')}
          description={get(page, 'hero.descriptionNode')}
          descriptions={index?.heroDescription}
          callToAction={get(page, 'hero.callToAction')}
        />
        {overlays.hero}
      </section>
      {page.customerServiceHeader && (
        <ActionNav header={page.customerServiceHeader} actions={page.customerService} />
      )}
      <section
        id="introduction"
        css={[global`layout.container`, global`layout.grid`, tw`py-24 lg:py-40`]}
      >
        <div css={tw`col-span-4 px-6 md:col-span-10 lg:(px-0 col-start-2 col-end-13)`}>
          <Text
            content={get(page, 'introduction.subtitleNode')}
            style={[global`typography.subtitle-lg`, tw`font-bold text-primary-500 mb-8`]}
          />
          <Heading
            headingType="h2"
            content={get(page, 'introduction.titleNode')}
            style={css`
              ${tw`text-primary-500`}
              b, strong {
                ${tw`font-medium`}
              }
            `}
          />
        </div>
      </section>
      <section id="video" css={tw`flex flex-col lg:(flex-row)`}>
        <div css={tw`-mb-24 pr-container-mobile lg:(flex-1 pr-0 mb-0 pt-32 self-end)`}>
          <VideoPlayer
            streamingUrl={get(page, 'introductionVideo.video.mp4Url')}
            thumbnailUrl={get(page, 'introductionVideoPoster.url')}
          />
        </div>
        <div
          css={tw`grid grid-cols-8 bg-accent-500 pb-20 pt-40 px-container-mobile lg:(flex-1 py-16 pl-24 -ml-24)`}
        >
          <LinkBlock
            title={get(page, 'introductionCta.title')}
            subtitle={get(page, 'introductionCta.subtitleNode')}
            description={get(page, 'introductionCta.descriptionNode')}
            callToAction={get(page, 'introductionCta.callToAction')}
            buttonTheme="navy-cyan"
            style={css`
              ${tw`col-span-8 lg:(col-start-2 col-end-7 justify-center)`}
              > h3 {
                ${tw`text-primary-500`}
              }
              > h6 {
                ${tw`text-primary-500 font-normal`}
              }
            `}
          />
        </div>
      </section>
      <section id="what-we-do" css={[global`layout.container`, tw`py-20 lg:(pt-40 pb-64)`]}>
        <HeaderBlock title={get(page, 'servicesTitle')} style={tw`mb-9 lg:(items-center mb-25)`} />
        <CardList cards={get(page, 'services')} />
      </section>
      <section id="partner-image" css={tw`relative flex flex-col`}>
        <FullCoverImage
          style={tw`order-1 -mt-32 lg:(mt-0 order-none)`}
          image={get(page, 'partnerBanner.image')}
          imageMobile={get(page, 'partnerBanner.imageMobile')}
        />
        <div
          css={[
            tw`relative inset-0 items-start flex w-full pr-container-mobile lg:(absolute grid -top-24)`,
            global`layout.desktop-container`,
            global`layout.desktop-grid`,
          ]}
        >
          <aside css={tw`bg-primary-500 z-1 sm:col-span-4 md:col-span-8 lg:col-span-5`}>
            <LinkBlock
              title={get(page, 'partnerBanner.title')}
              subtitle={get(page, 'partnerBanner.subtitle')}
              description={get(page, 'partnerBanner.descriptionNode')}
              callToAction={get(page, 'partnerBanner.callToAction')[0]}
              buttonTheme="cyan-white"
              style={css`
                ${tw`py-12 px-6 lg:(pt-16 pb-24 px-12)`}
                h3 {
                  ${tw`text-white`}
                }
                h5 {
                  ${tw`font-bold text-secondary-500`}
                }
                h6 {
                  ${tw`font-normal text-white text-lg leading-lg tracking-lg`}
                }
              `}
            />
          </aside>
        </div>
      </section>
      <section
        id="partner"
        css={[global`layout.container`, global`layout.grid`, tw`pb-20 relative`]}
      >
        {page.partnerLogosTitle && (
          <div
            css={[
              global`typography.text-vertical`,
              css`
                ${tw`text-grey text-opacity-50 col-span-4 mb-8 md:col-span-8 lg:(absolute top-1/2 mb-0 transform -rotate-90 -translate-x-1/2)`}
                ${lg} {
                  left: calc((100vw - ${theme`maxWidth.lg`}) / -4);
                }
                ${xl} {
                  left: calc((100vw - ${theme`maxWidth.xl`}) / -4);
                }
                ${xxl} {
                  left: calc((100vw - ${theme`maxWidth.xxl`}) / -4);
                }
              `,
            ]}
          >
            {get(page, 'partnerLogosTitle')}
          </div>
        )}
        <div css={tw`mt-12 col-span-4 md:col-span-8 lg:(col-span-7 mt-24) xl:col-span-10`}>
          <LogoGrid logos={get(page, 'partnerLogos')} />
        </div>
        <div
          css={tw`row-start-1 col-span-4 mt-16 mb-8 md:col-span-8 lg:(mb-0 mt-24 col-start-9 col-end-13) xl:(col-start-12 col-end-16)`}
        >
          <LinkBlock
            title={get(page, 'partnerCta.title')}
            description={get(page, 'partnerCta.descriptionNode')}
            callToAction={get(page, 'partnerCta.callToAction')}
            titleHeading="h4"
            buttonTheme="navy-cyan"
            style={css`
              h4 {
                ${tw`text-primary-500`}
                ${fluid(remToPx(theme`fontSize.mobile-2xl`), remToPx(theme`fontSize.mobile-4xl`))}
              }
              h6 {
                ${tw`font-normal text-lg leading-lg tracking-lg`}
              }
            `}
          />
        </div>
      </section>
      <section id="who-we-are" css={tw`bg-background-grey`}>
        <div css={[global`layout.container`, tw`flex items-center justify-center py-20 lg:py-32`]}>
          <LinkBlock
            title={get(page, 'whoWeAre.title')}
            subtitle={get(page, 'whoWeAre.subtitleNode')}
            description={get(page, 'whoWeAre.descriptionNode')}
            callToAction={get(page, 'whoWeAre.callToAction')}
            buttonTheme="cyan-navy"
            style={css`
              ${tw`items-start lg:(items-center max-w-1/2)`}
              h3, h5, h6 {
                ${tw`lg:text-center`}
              }
              h3,
              h5 {
                ${tw`text-primary-500`}
              }
            `}
          />
        </div>
      </section>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    index: PropTypes.object.isRequired,
  }),
  location: PropTypes.object,
}

export default IndexPage

export const query = graphql`
  query IndexQuery($locale: String!) {
    index: datoCmsKrHome(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      hero {
        image {
          format
          fluid(maxWidth: 1920, imgixParams: { fm: "jpg", auto: "compress", q: 75 }) {
            ...GatsbyDatoCmsFluid
          }
        }
        imageMobile {
          format
          fluid(maxWidth: 1023, imgixParams: { fm: "jpg", auto: "compress", q: 75 }) {
            ...GatsbyDatoCmsFluid
          }
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
        }
      }
      heroDescription {
        textNode {
          childMarkdownRemark {
            html
          }
        }
      }
      customerServiceHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      customerService {
        title
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
        }
      }
      introduction {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      introductionCta {
        title
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
        }
      }
      introductionVideo {
        video {
          thumbnailUrl(format: gif)
          streamingUrl
          mp4Url
        }
      }
      introductionVideoPoster {
        url
      }
      servicesTitle
      services {
        title
        image {
          format
          url
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
        }
      }
      partnerBanner {
        image {
          format
          fluid(maxWidth: 1920, imgixParams: { fm: "jpg", auto: "compress", q: 75 }) {
            ...GatsbyDatoCmsFluid
          }
        }
        imageMobile {
          format
          fluid(maxWidth: 1023, imgixParams: { fm: "jpg", auto: "compress", q: 75 }) {
            ...GatsbyDatoCmsFluid
          }
        }
        title
        subtitle
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
        }
      }
      partnerLogosTitle
      partnerLogos {
        url
        format
      }
      partnerCta {
        title
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
        }
      }
      whoWeAre {
        title
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
        }
      }
    }
  }
`
